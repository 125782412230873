/** @format */

import {
    PUBLIC_AWS_PROJECT_REGION,
    PUBLIC_API_ENDPOINT,
    PUBLIC_AWS_APPSYNC_REGION,
    PUBLIC_AWS_APPSYNC_AUTHENTICATION_TYPE,
    PUBLIC_USER_POOL_ID,
    PUBLIC_USER_POOL_WEB_CLIENT_ID,
} from '$env/static/public';

const awsConfig = {
    aws_project_region: PUBLIC_AWS_PROJECT_REGION,

    apiEndpoint: PUBLIC_API_ENDPOINT,
    aws_appsync_region: PUBLIC_AWS_APPSYNC_REGION,
    aws_appsync_authenticationType: PUBLIC_AWS_APPSYNC_AUTHENTICATION_TYPE,

    aws_user_pools_id: PUBLIC_USER_POOL_ID,
    aws_user_pools_web_client_id: PUBLIC_USER_POOL_WEB_CLIENT_ID,
};

export default awsConfig;
