import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94')
];

export const server_loads = [2,3,5,6,7,9,10,11,12,13,14,15];

export const dictionary = {
		"/(app)/admin": [~18,[2,3]],
		"/(app)/admin/issued-invoices": [~19,[2,3]],
		"/(app)/admin/link-terminal": [~20,[2,3]],
		"/(app)/admin/received-invoices": [~21,[2,3]],
		"/(app)/admin/terminals": [~22,[2,3]],
		"/(app)/dashboard": [23,[2,4]],
		"/(app)/dashboard/new-loyality-program": [~24,[2,4]],
		"/(app)/dashboard/new-partner": [~25,[2,4]],
		"/(app)/partner/[partnerId]": [~26,[2,5]],
		"/(app)/partner/[partnerId]/activity": [27,[2,5]],
		"/(app)/partner/[partnerId]/billing": [~28,[2,5]],
		"/(app)/partner/[partnerId]/branding": [~29,[2,5]],
		"/(app)/partner/[partnerId]/delete-partner": [30,[2,5]],
		"/(app)/partner/[partnerId]/description": [~31,[2,5]],
		"/(app)/partner/[partnerId]/members": [~32,[2,5]],
		"/(app)/partner/[partnerId]/modules": [33,[2,5]],
		"/(app)/partner/[partnerId]/modules/analytics/data-hub": [~34,[2,5,6]],
		"/(app)/partner/[partnerId]/modules/analytics/info": [35,[2,5,6]],
		"/(app)/partner/[partnerId]/modules/fanpoints/commission-rates": [~36,[2,5,7]],
		"/(app)/partner/[partnerId]/modules/fanpoints/discount-codes": [~37,[2,5,7]],
		"/(app)/partner/[partnerId]/modules/fanpoints/info": [38,[2,5,7]],
		"/(app)/partner/[partnerId]/modules/fanpoints/transactions": [~39,[2,5,7]],
		"/(app)/partner/[partnerId]/modules/fanpoints/upload-discount-codes": [40,[2,5,7,8]],
		"/(app)/partner/[partnerId]/modules/fanpoints/upload-discount-codes/insert": [41,[2,5,7,8]],
		"/(app)/partner/[partnerId]/modules/fanpoints/upload-discount-codes/upload": [42,[2,5,7,8]],
		"/(app)/partner/[partnerId]/modules/products/distribution": [~43,[2,5]],
		"/(app)/partner/[partnerId]/modules/products/edit-product/[[rewardId]]": [~44,[2,5]],
		"/(app)/partner/[partnerId]/modules/products/info": [45,[2,5]],
		"/(app)/partner/[partnerId]/modules/products/products": [~46,[2,5]],
		"/(app)/partner/[partnerId]/modules/promotions/edit-promotion/[[rewardId]]/[[distributionPolicyId]]": [~47,[2,5]],
		"/(app)/partner/[partnerId]/modules/promotions/info": [48,[2,5]],
		"/(app)/partner/[partnerId]/modules/promotions/promotions": [~49,[2,5]],
		"/(app)/partner/[partnerId]/modules/sales/auctions": [~50,[2,5]],
		"/(app)/partner/[partnerId]/modules/sales/edit-auction/[[rewardId]]/[[distributionPolicyId]]": [~51,[2,5]],
		"/(app)/partner/[partnerId]/modules/sales/edit-lottery/[[rewardId]]/[[distributionPolicyId]]": [~52,[2,5]],
		"/(app)/partner/[partnerId]/modules/sales/edit-offer/[[rewardId]]/[[distributionPolicyId]]": [~53,[2,5]],
		"/(app)/partner/[partnerId]/modules/sales/info": [54,[2,5]],
		"/(app)/partner/[partnerId]/modules/sales/lotteries": [~55,[2,5]],
		"/(app)/partner/[partnerId]/modules/sales/offers": [~56,[2,5]],
		"/(app)/partner/[partnerId]/modules/statuspoints/info": [57,[2,5,9]],
		"/(app)/partner/[partnerId]/modules/statuspoints/transactions": [~58,[2,5,9]],
		"/(app)/partner/[partnerId]/partner-id": [59,[2,5]],
		"/(app)/partner/[partnerId]/partners": [~60,[2,5]],
		"/(app)/partner/[partnerId]/tokens": [~61,[2,5]],
		"/(app)/project/[projectId]": [~62,[2,10]],
		"/(app)/project/[projectId]/activity": [63,[2,10]],
		"/(app)/project/[projectId]/billing": [~64,[2,10]],
		"/(app)/project/[projectId]/branding": [~65,[2,10]],
		"/(app)/project/[projectId]/delete-project": [66,[2,10]],
		"/(app)/project/[projectId]/description": [~67,[2,10]],
		"/(app)/project/[projectId]/members": [~68,[2,10]],
		"/(app)/project/[projectId]/modules": [69,[2,10]],
		"/(app)/project/[projectId]/modules/achievements/info": [70,[2,10,11]],
		"/(app)/project/[projectId]/modules/achievements/manage": [~71,[2,10,11]],
		"/(app)/project/[projectId]/modules/analytics/data-hub": [~72,[2,10,12]],
		"/(app)/project/[projectId]/modules/analytics/info": [73,[2,10,12]],
		"/(app)/project/[projectId]/modules/marketplace/info": [74,[2,10,13]],
		"/(app)/project/[projectId]/modules/marketplace/items": [~75,[2,10,13]],
		"/(app)/project/[projectId]/modules/marketplace/partners": [~76,[2,10,13]],
		"/(app)/project/[projectId]/modules/marketplace/proposal/[rewardId]/[distributionPolicyId]/[policyPartnerId]": [~77,[2,10,13]],
		"/(app)/project/[projectId]/modules/prizes/info": [78,[2,10]],
		"/(app)/project/[projectId]/modules/prizes/partners": [~79,[2,10]],
		"/(app)/project/[projectId]/modules/prizes/prizes": [~80,[2,10]],
		"/(app)/project/[projectId]/modules/prizes/proposal/[rewardId]/[distributionPolicyId]/[policyPartnerId]": [~81,[2,10]],
		"/(app)/project/[projectId]/modules/statuspoints/info": [82,[2,10,14]],
		"/(app)/project/[projectId]/modules/users/info": [83,[2,10,15]],
		"/(app)/project/[projectId]/modules/users/manage": [~84,[2,10,15]],
		"/(app)/project/[projectId]/modules/users/wallet": [~85,[2,10,15]],
		"/(app)/project/[projectId]/partners": [~86,[2,10]],
		"/(app)/project/[projectId]/project-id": [87,[2,10]],
		"/(app)/project/[projectId]/tokens": [~88,[2,10]],
		"/(auth)/reset-password": [90,[17]],
		"/(auth)/reset-password/confirm": [91,[17]],
		"/(app)/settings": [89,[2,16]],
		"/(auth)/sign-in": [92,[17]],
		"/(auth)/sign-up": [93,[17]],
		"/(auth)/sign-up/confirm": [94,[17]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';